/* flexbox*/
.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  padding: 0;
}
.slick-slider,
.slick-slider *,
.wrapper *,
.box * {
  box-sizing: border-box;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

td,
th {
  border-bottom: unset;
}

body {
  margin: 0px;
}